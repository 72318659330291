<script setup lang="ts">
import Layout from '@/views/Layout/Layout.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import locale from 'ant-design-vue/es/locale/it_IT'
import { ConfigProvider } from 'ant-design-vue'
import dayjs from 'dayjs'
import 'dayjs/locale/it-ch'

dayjs.locale('it-ch')

const route = useRoute()
const isLayoutAuth = computed(() => !route.meta.isProtected)
const hasGradientHeader = computed(() => route.meta.hasGradientHeader)
</script>

<template>
    <ConfigProvider :locale="locale">
        <router-view v-slot="{ Component }">
            <Layout v-if="!isLayoutAuth" :gradient-header="hasGradientHeader">
                <transition mode="out-in" name="fade">
                    <component :is="Component" />
                </transition>
            </Layout>
            <Layout v-else :gradient-header="hasGradientHeader" auth>
                <transition mode="out-in" name="fade">
                    <component :is="Component" />
                </transition>
            </Layout>
        </router-view>
    </ConfigProvider>
</template>
